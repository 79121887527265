import React, { useState, useEffect, useRef } from "react";
import "./Snake.css";
import foodImage from "./food.png";
import bgImage from "./bg.png";
import snakeImage from "./snake.png";
import cornerImage from "./snake.png";
import Pic2Image from "./image.png";
import loserImg from "./loser.png";
import Pic3Image from "./food.png";
import Modal from "./Modal";
import soundFile from "./sound1.mp3";

const contractAddress = ""; // Ersätt med din faktiska kontraktsadress

const Snake = () => {
	const canvasWidth = 230; // Justera efter storleken på skärmen på din Nokia-bild
	const canvasHeight = 170; // Justera efter storleken på skärmen på din Nokia-bild
	const blockSize = 10;
	const initialSnake = [{ x: 100, y: 100 }];
	const initialFood = getRandomCoordinates();
	const [snake, setSnake] = useState(initialSnake);
	const [food, setFood] = useState(initialFood);
	const [direction, setDirection] = useState("RIGHT");
	const [isGameOver, setIsGameOver] = useState(false);
	const [isGameStarted, setIsGameStarted] = useState(false);
	const [score, setScore] = useState(0);
	const [showModal, setShowModal] = useState(!localStorage.getItem("hasSeenWelcome"));
	const [isPlaying, setIsPlaying] = useState(false);
	const audioRef = useRef(new Audio(soundFile));

	useEffect(() => {
		const handleKeyDown = (e) => {
			if (!isGameStarted) {
				setIsGameStarted(true);
				setShowModal(false);
			}
			switch (e.key) {
				case "ArrowUp":
				case "w":
				case "W":
					setDirection("UP");
					break;
				case "ArrowDown":
				case "s":
				case "S":
					setDirection("DOWN");
					break;
				case "ArrowLeft":
				case "a":
				case "A":
					setDirection("LEFT");
					break;
				case "ArrowRight":
				case "d":
				case "D":
					setDirection("RIGHT");
					break;
				case "r":
				case "R":
					restartGame();
					break;
				default:
					break;
			}
		};
		document.addEventListener("keydown", handleKeyDown);
		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [isGameStarted]);

	useEffect(() => {
		if (isGameOver || !isGameStarted) return;
		const interval = setInterval(moveSnake, 100);
		return () => clearInterval(interval);
	}, [snake, direction, isGameOver, isGameStarted]);

	function getRandomCoordinates() {
		const x = Math.floor(Math.random() * (canvasWidth / blockSize)) * blockSize;
		const y = Math.floor(Math.random() * (canvasHeight / blockSize)) * blockSize;
		return { x, y };
	}

	function moveSnake() {
		const newSnake = [...snake];
		const head = { ...newSnake[0] };

		switch (direction) {
			case "UP":
				head.y -= blockSize;
				break;
			case "DOWN":
				head.y += blockSize;
				break;
			case "LEFT":
				head.x -= blockSize;
				break;
			case "RIGHT":
				head.x += blockSize;
				break;
			default:
				break;
		}

		newSnake.unshift(head);

		if (head.x === food.x && head.y === food.y) {
			setFood(getRandomCoordinates());
			setScore(score + 1);
		} else {
			newSnake.pop();
		}

		if (checkCollision(head, newSnake)) {
			setIsGameOver(true);
		} else {
			setSnake(newSnake);
		}
	}

	function checkCollision(head, snake) {
		for (let i = 1; i < snake.length; i++) {
			if (head.x === snake[i].x && head.y === snake[i].y) {
				return true;
			}
		}
		if (head.x < 0 || head.x >= canvasWidth || head.y < 0 || head.y >= canvasHeight) {
			return true;
		}
		return false;
	}

	function restartGame() {
		setSnake(initialSnake);
		setFood(initialFood);
		setDirection("RIGHT");
		setIsGameOver(false);
		setIsGameStarted(false);
		setScore(0);
		setShowModal(false); // Visa inte modalen igen vid omstart
	}

	function copyContractAddress() {
		navigator.clipboard.writeText(contractAddress);
		alert("Contract address copied to clipboard!");
	}

	function togglePlayPause() {
		if (isPlaying) {
			audioRef.current.pause();
		} else {
			audioRef.current.play().catch((error) => {
				console.error("Failed to play audio:", error);
			});
		}
		setIsPlaying(!isPlaying);
	}
	return (
		<div className="screen">
			<div className="game-container">
				<h1 className="heading">TATE Snake</h1>
				<img src={bgImage} className="bg-image" alt="background" />
				<div className="canvas" style={{ top: "260px", left: "275px", width: canvasWidth, height: canvasHeight }}>
					{snake.map((segment, index) => (
						<img key={index} src={snakeImage} className="snake-segment" style={{ top: segment.y, left: segment.x }} alt="snake" />
					))}
					<img src={foodImage} className="food" style={{ top: food.y, left: food.x }} alt="food" />
					{isGameOver && <div className="game-over">Game Over!</div>}
				</div>
				<div className="score">Score: {score}</div>
				{isGameOver && (
					<>
						<h1 className="restart-button-1">Fucking LOSER</h1>
						{/* <img src={loserImg} className="restart-button" alt="corner" /> */}
						<button className="restart-button" onClick={restartGame}>
							Restart
						</button>
					</>
				)}
				{showModal && <Modal onClose={() => setShowModal(false)} />}
			</div>
			<div className="socials">
				<a href="https://x.com/PepMangione/status/1750153280121102345" target="_blank" rel="noopener noreferrer">
					Twitter
				</a>
				<a href="https://t.me/+z4ZRq0aub9dmODA0" target="_blank" rel="noopener noreferrer">
					Telegram
				</a>
				<a href="https://pump.fun/board" target="_blank" rel="noopener noreferrer">
					Chart
				</a>
			</div>
			<div className="copy-container">
				<button className="copy-button" onClick={copyContractAddress}>
					Copy Contract Address
				</button>
			</div>
			<img src={cornerImage} className="corner-image" alt="corner" />
			<img src={Pic2Image} className="corner-image-2" alt="corner" />
			<img src={Pic3Image} className="corner-image-3" alt="corner" />

			<div className="controls-info">
				<p>Use Arrow Keys or WASD to Move</p>
				<p>Press 'R' to Restart</p>
			</div>
		</div>
	);
};

export default Snake;
