import React, { useEffect, useRef } from "react";
import "./Modal.css";
import soundFile from "./sound1.mp3";
import pic from "./pic.gif";

const Modal = ({ onClose }) => {
	const audioRef = useRef(null);

	useEffect(() => {
		audioRef.current = new Audio(soundFile);
	}, []);

	const playSound = () => {
		audioRef.current.play();
	};

	const handleClose = () => {
		playSound();
		onClose();
	};

	return (
		<div className="modal-backdrop ">
			<div className="modal-content">
				<h2>LUIGI GOES HUNTING!</h2>
				{/* <p>Control Luigi with the arrows. and hunt!</p> */}

				<button onClick={handleClose} className="">
					Start Game
				</button>
			</div>
		</div>
	);
};

export default Modal;
