import React from "react";
import Snake from "./Snake";

function App() {
	return (
		<div className="App">
			<Snake />
		</div>
	);
}

export default App;
